import { Toaster } from 'react-hot-toast';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { SettingsButton } from '../components/settings-button';
import { SplashScreen } from '../components/splash-screen';
import { SettingsConsumer, SettingsProvider } from '../contexts/settings-context';
import { AuthConsumer, AuthProvider } from '../contexts/GardianWebSSOAuthContext';
import { createTheme } from '../theme';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from '../store';
import { createEmotionCache } from '../utils/create-emotion-cache';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import numeral from 'numeral';// load a locale
// switch between locales
// numeral.locale('fr');

const clientSideEmotionCache = createEmotionCache();

const App = (props) => {
  const content = useRoutes(routes);
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider >
        <Helmet>
          <title>
            Ened.is
          </title>
          <meta
            name="viewport"
            content="initial-scale=1, width=device-width"
          />
        </Helmet>
        <ReduxProvider store={store}>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AuthProvider>
              <SettingsProvider>
                <SettingsConsumer>
                  {({ settings }) => (
                    <ThemeProvider
                      theme={createTheme({
                        mode: settings.theme
                      })}
                    >
                      <CssBaseline />
                      <Toaster position="top-center" />
                      <SettingsButton />
                      <AuthConsumer>
                        {(auth) => !auth.isInitialized
                          ? <SplashScreen />
                          : content}
                      </AuthConsumer>
                    </ThemeProvider>
                  )}
                </SettingsConsumer>
              </SettingsProvider>
            </AuthProvider>
          </LocalizationProvider>
        </ReduxProvider>

      </HelmetProvider>

    </CacheProvider >
  );
};

export default App;
