import PropTypes from 'prop-types';
import { Link as RouterLink } from "react-router-dom";

import { AppBar, Box, Button, Container, IconButton, Link, Toolbar } from '@mui/material';
import { Menu as MenuIcon } from '../icons/menu';
import { Logo } from './logo';

export const MainNavbar = (props) => {
  const { onOpenSidebar } = props;

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        borderBottomColor: 'divider',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        color: 'text.secondary'
      }}
    >
      <Container maxWidth="lg">
        <Toolbar
          disableGutters
          sx={{ minHeight: 64 }}
        >
          <RouterLink to={'/'}>
            <Logo
              component={RouterLink} to='/'
              sx={{
                display: {
                  md: 'inline',
                  xs: 'none'
                },
                height: 40,
                width: 40
              }}
            />
          </RouterLink>


          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            color="inherit"
            onClick={onOpenSidebar}
            sx={{
              display: {
                md: 'none'
              }
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box
            sx={{
              alignItems: 'center',
              display: {
                md: 'flex',
                xs: 'none'
              }
            }}
          >
            <Button
              component={RouterLink} to="/dashboard"
              size="medium"
              sx={{ ml: 2 }}
              variant="contained"
            >
              Mon espace
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar >
  );
};

MainNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};
