import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthGuard } from '../components/authentication/auth-guard';
import ACL from '../components/authentication/ACL';
import LoadingScreen from '../components/LoadingScreen';
import { MainLayout } from '../components/main-layout';
import { DashboardLayout } from '../components/dashboard/dashboard-layout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// // Dashboard pages

const General = Loadable(lazy(() => import('../pages/dashboard/')));
const Url = Loadable(lazy(() => import('../pages/dashboard/url/')));
const UrlList = Loadable(lazy(() => import('../pages/dashboard/url/list')));
const UrlEdit = Loadable(lazy(() => import('../pages/dashboard/url/edit')));
const Analytics = Loadable(lazy(() => import('../pages/dashboard/analytics')));
// const Chat = Loadable(lazy(() => import('./pages/dashboard/Chat')));
// const CustomerDetails = Loadable(lazy(() => import('./pages/dashboard/CustomerDetails')));
// const CustomerEdit = Loadable(lazy(() => import('./pages/dashboard/CustomerEdit')));
// const CustomerList = Loadable(lazy(() => import('./pages/dashboard/CustomerList')));
// const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
// const InvoiceDetails = Loadable(lazy(() => import('./pages/dashboard/InvoiceDetails')));
// const InvoiceList = Loadable(lazy(() => import('./pages/dashboard/InvoiceList')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/kanban')));
// const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
// const OrderDetails = Loadable(lazy(() => import('./pages/dashboard/OrderDetails')));
// const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
// const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
// const ProductCreate = Loadable(lazy(() => import('./pages/dashboard/ProductCreate')));
// const ProductList = Loadable(lazy(() => import('./pages/dashboard/ProductList')));


// // Other pages
const Home = Loadable(lazy(() => import('./index')));

// // Error pages
const AuthorizationRequired = Loadable(lazy(() => import('../pages/401')));
const NotFound = Loadable(lazy(() => import('../pages/404')));
const ServerError = Loadable(lazy(() => import('../pages/500')));


const routes = [
  {
    path: 'dashboard/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <General />
      },
      {
        path: 'kanban',
        element: <Kanban />
      },
      {
        path: 'url',
        children: [
          {
            path: '',
            element: <UrlList />
          },
          {
            path: ':urlId',
            element: <Url />
          },
          {
            path: ':urlId/edit',
            element: <UrlEdit />,
          }
        ]
      },
      {
        path: 'analytics',
        element: (
          <ACL allowedRoles={['8GE_ADMIN']} fallback={<AuthorizationRequired />}>
            <Analytics />
          </ACL>
        )
      },
      {
        path: 'workspace',
        children: [
          {
            path: '',
            element: (
              <ACL allowedRoles={['8GE_ADMIN']} fallback={<AuthorizationRequired />}>
                <UrlList />
              </ACL>
            )
          },
          {
            path: ':workspaceId',
            element: (
              <ACL allowedRoles={['8GE_ADMIN']} fallback={<AuthorizationRequired />}>
                <Url />
              </ACL>
            )
          },
          {
            path: ':workspaceId/edit',
            element: (
              <ACL allowedRoles={['8GE_ADMIN']} fallback={<AuthorizationRequired />}>
                <UrlEdit />
              </ACL>
            ),
          }
        ]
      },
      {
        path: 'user',
        children: [
          {
            path: '',
            element: (
              <ACL allowedRoles={['8GE_ADMIN']} fallback={<AuthorizationRequired />}>
                <UrlList />
              </ACL>
            )
          },
          {
            path: ':userId',
            element: (
              <ACL allowedRoles={['8GE_ADMIN']} fallback={<AuthorizationRequired />}>
                <Url />
              </ACL>
            )
          },
          {
            path: ':userId/edit',
            element: (
              <ACL allowedRoles={['8GE_ADMIN']} fallback={<AuthorizationRequired />}>
                <UrlEdit />
              </ACL>
            ),
          }
        ]
      },

      // {
      //   path: 'mail',
      //   children: [
      //     {
      //       path: '/',
      //       element: (
      //         <Navigate
      //           to="/dashboard/mail/all"
      //           replace
      //         />
      //       )
      //     },
      //     {
      //       path: 'label/:customLabel',
      //       element: <Mail />
      //     },
      //     {
      //       path: 'label/:customLabel/:emailId',
      //       element: <Mail />
      //     },
      //     {
      //       path: ':systemLabel',
      //       element: <Mail />
      //     },
      //     {
      //       path: ':systemLabel/:emailId',
      //       element: <Mail />
      //     }
      //   ]
      // },
    ]
  },
  {
    path: '401',
    element: <AuthorizationRequired />
  },
  {
    path: '404',
    element: <NotFound />
  },
  {
    path: '500',
    element: <ServerError />
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <MainLayout>
          <Home />
        </MainLayout>
      </AuthGuard>
    ),
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
