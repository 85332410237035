// import 'react-perfect-scrollbar/dist/css/styles.css';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import 'react-quill/dist/quill.snow.css';
// import 'nprogress/nprogress.css';
// import './__mocks__';
// import { StrictMode } from 'react';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import track from "react-tracking";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import App from './pages/App';

const TrackedApp = track(
    // app-level tracking data
    { app: "Ened.is", INS: "8GE", session: uuidv4() },

    // top-level options
    {
        // custom dispatch to console.log in addition to pushing to dataLayer[]
        dispatch: async data => {
            try {
                const res = await axios.post('/api/system/tracking/', data);
                // console.log(res);
                // console.log(data);
                // console.log(window.dataLayer);
                (window.dataLayer = window.dataLayer || []).push(data);
            } catch (err) {
                console.error(err);
            }

        }
    }
)(App);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<BrowserRouter>
    <TrackedApp />
</BrowserRouter>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
