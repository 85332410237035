import PropTypes from 'prop-types';
import { List, ListSubheader } from '@mui/material';
import { DashboardSidebarItem } from './dashboard-sidebar-item';
import ACL from '../../components/authentication/ACL';

const renderNavItems = ({ depth = 0, items, path }) => (
  <List disablePadding>
    {items.reduce((acc, item) => reduceChildRoutes({
      acc,
      item,
      depth,
      path
    }), [])}
  </List>
);

const reduceChildRoutes = ({ acc, item, depth, path }) => {
  const key = `${item.title}-${depth}`;
  const partialMatch = path.includes(item.path);
  const exactMatch = path === item.path;

  if (item.children) {
    acc.push(
      <ACL allowedRoles={item.allowedRoles} key={key}>
        <DashboardSidebarItem
          active={partialMatch}
          chip={item.chip}
          depth={depth}
          icon={item.icon}
          info={item.info}
          open={partialMatch}
          path={item.path}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            items: item.children,
            path
          })}
        </DashboardSidebarItem>
      </ACL>

    );
  } else {
    acc.push(
      <ACL allowedRoles={item.allowedRoles} key={key}>
        <DashboardSidebarItem
          active={exactMatch}
          chip={item.chip}
          depth={depth}
          icon={item.icon}
          info={item.info}
          path={item.path}
          title={item.title}
        />
      </ACL>

    );
  }

  return acc;
};

export const DashboardSidebarSection = (props) => {
  const { items, path, title, allowedRoles, ...other } = props;

  return (
    <ACL allowedRoles={allowedRoles}>

      <List
        subheader={(
          <ListSubheader
            disableGutters
            disableSticky
            sx={{
              color: 'neutral.500',
              fontSize: '0.75rem',
              fontWeight: 700,
              lineHeight: 2.5,
              ml: 4,
              textTransform: 'uppercase'
            }}
          >
            {title}
          </ListSubheader>
        )}
        {...other}>
        {renderNavItems({
          items,
          path
        })}
      </List>
    </ACL>

  );
};

DashboardSidebarSection.propTypes = {
  items: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
